* {
  box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Roboto";

  }
  
  #root {
    position: relative;
    overflow: hidden;
  }
